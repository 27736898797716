<template>
  <div class="game-master-page fill-screen">
    <div class="column-shifter" :class="focusColumn">

      <section class="column">
        <div class="container">
          <button v-show="signinState>=1" class="terminal-button float-right" @click="signOut">Sign Out</button>
          <h2>Game Master Page</h2>

          <p v-show="signinState===-1">Checking...</p>

          <div v-show="signinState===0">
            <p>Sign in to continue...</p>
            <div class="form-group">
              <label>email:</label><input type="email" v-model="signinForm.user" @keyup.enter="signIn">
            </div>
            <div class="form-group">
              <label>password:</label><input type="password" v-model="signinForm.pass" @keyup.enter="signIn">
            </div>
            <div>
              <button class="terminal-button" @click="signIn">
                Sign In
              </button>
            </div>
            <hr>
            <div>
              <button class="signin-button" @click="signInThroughProvider('google')">
                <img class="signin-method-icon" alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg">
                Sign In with Google
              </button>
            </div>
          </div>

          <div v-show="signinState>=1">
            <button v-show="signinState==6" class="terminal-button float-right" @click="showPwResetForm">PW Reset</button>
            <p>Welcome, {{userDisplayName}}.</p>
          </div>

          <div v-show="signinState===1">
          <p>This signin account is not configured as an Admin/GM.</p>
          </div>

          <div v-show="signinState===2">
            <p>Password reset required.</p>
            <div class="form-group" v-if="!justSignedIn">
              <label>old password:</label><input type="password" v-model="pwResetForm.oldPass">
            </div>
            <div class="form-group">
              <label>new password:</label><input type="password" v-model="pwResetForm.pass1">
            </div>
            <div class="form-group">
              <label>retype new password:</label><input type="password" v-model="pwResetForm.pass2">
            </div>
            <div>
              <button class="terminal-button" :disabled="passwordsDontMatch" @click="submitPwReset">
                Set New Password
              </button>
            </div>
          </div>

          <div v-show="signinState===3">
            <div v-if="otpQrUrl">
              <p>Scan this QR code with your authentication app to complete OTP setup.</p>
              <p class="text-center"><img :src="otpQrUrl"></p>
              <p>You should now have a new entry for "Cyber Escape Room".</p>
              <button class="terminal-button" @click="checkOtpStatus">
                Confirm
              </button>
            </div>
            <div v-else>
              <p class="lead">This site uses two-factor authentication to protect you and your company's information.</p>
              <p>After your regular login, you will be prompted for a "one-time password" (OTP), which is generated by a special OTP authentication app.</p>
              <p>If you don't already have one, on your smartphone, install a password manager app that supports OTP.</p>
              <p>Example apps that support OTP:</p>
              <ul style="margin-left:1rem">
                <li>Duo Mobile</li>
                <li>Google Authenticator</li>
                <li>LastPass Authenticator</li>
                <li>Microsoft Authenticator</li>
                <li>Twilio Authy</li>
              </ul>
              <p class="lead">Once your OTP authentication app is ready, generate an OTP code and scan it with your phone's camera to complete the setup process. <u><em>This can only be done once!</em></u></p>
              <div>
                <button class="terminal-button" @click="generateOtpSeed">
                  Generate OTP Code
                </button>
              </div>
            </div>
          </div>

          <!-- <div v-show="signinState===3">
            <p>Set up multi-factor authentication.</p>
            <div class="form-group">
              <label>mobile phone number:</label><input type="tel" v-model="signinPhone.text" @keyup="parsePhoneNumber(signinPhone, $event)">
            </div>
            <div>
              <button ref="linkPhoneButton" class="terminal-button" :disabled="!signinPhone.valid" @click="linkPhone">
                Link Phone Number
              </button>
            </div>
          </div>

          <div v-show="signinState===4">
            <p>mobile phone number: {{ userPhoneNumber }}</p>
            <div>
              <button ref="signinButton" class="terminal-button" @click="sendPhoneCode">
                Send Verification Code to Phone
              </button>
            </div>
          </div>

          <div v-show="signinState===5">
            <p>Code sent to the phone number in your profile.</p>
            <div class="form-group">
              <label>enter code:</label><input type="text" v-model="signinPhone.code" @keyup.enter="confirmPhoneCode">
            </div>
            <div>
              <button class="terminal-button" @click="confirmPhoneCode">
                Confirm Phone Code
              </button>
            </div>
          </div> -->

          <div v-show="signinState===4">
            <p>One-Time Password from Authenticator app required.</p>
            <div class="form-group">
              <label>enter code:</label><input type="text" v-model="otpEntry" @keyup.enter="checkOtp">
            </div>
            <div>
              <button class="terminal-button" @click="checkOtp">
                Submit
              </button>
            </div>
          </div>

          <div v-show="signinState===5">
            <p>Shall we play a game?</p>

            <div v-show="showingPwResetForm">
              <p>Password reset:</p>
              <div class="form-group" v-if="!justSignedIn">
                <label>old password:</label><input type="password" v-model="pwResetForm.oldPass">
              </div>
              <div class="form-group">
                <label>new password:</label><input type="password" v-model="pwResetForm.pass1">
              </div>
              <div class="form-group">
                <label>retype new password:</label><input type="password" v-model="pwResetForm.pass2">
              </div>
              <div>
                <button class="terminal-button" :disabled="passwordsDontMatch" @click="submitPwReset">
                  Set New Password
                </button>
              </div>
            </div>

          </div>
        </div>

        <div v-if="signinState===5">
          <div class="container">
            <h3>Sessions List:</h3>

            <div class="form-group">
              <label>Scheduled Between:</label>
              <div class="text-center">
                <input type="date" v-model="showSessionsFromDate" @change="onFromDateChange">&nbsp;-&nbsp;
                <input type="date" v-model="showSessionsToDate" @change="onToDateChange">
              </div>
            </div>
            <div><label><input type="checkbox" v-model="showAnyTimeSessions"> Show &quot;Any Time&quot; Sessions</label></div>
            <div><label><input type="checkbox" v-model="showConcludedSessions"> Show Concluded Sessions</label></div>

            <table class="sessions-table">
              <thead>
                <tr>
                  <th></th>
                  <th>Sessions:</th>
                  <th>Scheduled For</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="id in sortedSessionIds" :key="id" :class="tableClassOfSessionId(id)" @click="selectSession(sessions[id])">
                  <td><span v-if="id===selectedSessionId">&gt;</span><span v-else>&nbsp;</span></td>
                  <td>{{id}}</td>
                  <td>{{sessions[id].date}} {{sessions[id].time}}</td>
                </tr>
              </tbody>
            </table>
            <p v-if="sortedSessionIds.length===0"><em>No matching sessions.</em></p>

            <p class="clickable" @click="selectSession(null)">+ Create New Session</p>
          </div>
        </div>

      </section>
      <section class="column" v-if="selectedSession">

        <div class="container" v-if="selectedSession.id">
          <button class="terminal-button danger-button float-right" @click="confirmDeleteSession"><i class="bi bi-trash"></i></button>
          <h3>Session Settings:</h3>
          <p>ID: {{selectedSession.id}}</p>
          <div class="form-group">
            <label>Scheduled For:</label>
            <input type="date" v-model="selectedSession.date">
            <input type="time" v-model="selectedSession.time">
          </div>
          <p><label><input type="checkbox" v-model="selectedSession.open"> Open</label></p>
          <p><label><input type="checkbox" v-model="selectedSession.allowSelfStart"> Allow Self-Start</label></p>
          <p><label><input type="checkbox" v-model="selectedSession.started"> Started</label></p>
          <p><label><input type="checkbox" v-model="selectedSession.concluded"> Concluded</label></p>
          <button class="big-button" @click="saveSelectedSession">Save</button>
        </div>

        <div class="container" v-else>
          <h3>Create New Session:</h3>
          <label>Session PIN</label>
          <input type="text" v-model="newSessionPin">
          <p><label><input type="checkbox" v-model="newSession.open"> Open</label></p>
          <p><label><input type="checkbox" v-model="newSession.allowSelfStart"> Allow Self-Start</label></p>
          <button class="big-button" @click="createNewSession">Create</button>
        </div>

        <div class="container">
          <button class="terminal-button danger-button float-right" @click="confirmResetTeams"><i class="bi bi-recycle"></i></button>
          <h3>Teams:</h3>
          <table class="teams-table">
            <thead>
              <tr>
                <th></th>
                <th>Team Name</th>
                <th>Stage</th>
                <th>Time</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(thisTeam) in sortedTeams" :key="thisTeam.id" class="clickable" :class="{'text-white': thisTeam.id===selectedTeamId, 'text-red': allTeams[thisTeam.id].requiresAssistance}" @click="selectTeam(thisTeam)">
                <td>
                  <span v-if="thisTeam.id===selectedTeamId">&gt;</span><span v-else>&nbsp;</span>
                </td>
                <td>{{thisTeam.name}}</td>
                <td v-html="teamStatus(allTeams[thisTeam.id])"></td>
                <td>{{thisTeam.timeText}}</td>
                <td>{{thisTeam.score}}</td>
              </tr>
            </tbody>
          </table>

          <p v-if="selectedSession.started">Game in progress</p>
          <button v-else class="big-button" @click="startSession">Start!</button>
        </div>

        <div class="container">
          <p>Completed Training:</p>
          <ul>
            <li v-for="member in selectedSessionCompletedTraining" :key="member">{{member}}</li>
          </ul>
        </div>

      </section>
      <section class="column" v-else-if="signinState===5">
        <div class="container">
          <h3>Create New Session:</h3>
          <label>Session PIN</label>
          <input type="text" v-model="newSessionPin">
          <p v-show="newSessionPinIsDuplicate" class="text-warning">Invalid PIN -- Already used.</p>
          <p><label><input type="checkbox" v-model="newSession.open"> Open</label></p>
          <p><label><input type="checkbox" v-model="newSession.allowSelfStart"> Allow Self-Start</label></p>
          <button class="big-button" :disabled="newSessionPinIsDuplicate" @click="createNewSession">Create</button>
        </div>
      </section>
      <section class="column" v-if="selectedTeam">

        <div class="container">
          <h3>Team Status:</h3>
          <p v-if="selectedTeam.requiresAssistance" class="text-red" @click="clearRequestForAssistance"><i class="bi-flag-fill"></i> Requires Assistance</p>
          <p>Name: {{selectedTeam.name}}</p>
          <p>Members:</p>
          <ul>
            <li v-for="(member, m) in selectedTeam.members" :key="m">{{member}}</li>
          </ul>
          <hr>
          <p>Hints Remaining: {{selectedTeam.hintsRemaining}} <button @click="addHint"><i class="bi bi-plus"></i></button> <button @click="subtractHint"><i class="bi bi-dash"></i></button></p>
          <p>Time Penalty: {{selectedTeam.timePenalty}} seconds <button @click="addPenalty"><i class="bi bi-plus"></i></button> <button @click="subtractPenalty"><i class="bi bi-dash"></i></button></p>
          <hr>
          <p>Location: {{selectedTeam.lastLocation}}</p>
          <p><label><input type="checkbox"
            v-model="selectedTeam.ready"
            @change="updateTeamProp('ready')"> Ready</label></p>
          <p><label><input type="checkbox"
            v-model="selectedTeam.completedIntroduction"
            @change="updateTeamProp('completedIntroduction')"> Introduction</label></p>
          <p><label><input type="checkbox"
            v-model="selectedTeam.completedCentralParkVideo"
            @change="updateTeamProp('completedCentralParkVideo')"> Central Park Video</label></p>
          <p><label><input type="checkbox"
            v-model="selectedTeam.completedCentralPark"
            @change="updateTeamProp('completedCentralPark')"> Central Park<span class="float-right">{{selectedTeamCentralParkTime}}</span></label></p>
          <p><label><input type="checkbox"
            v-model="selectedTeam.completedAquaTheaterVideo"
            @change="updateTeamProp('completedAquaTheaterVideo')"> Aqua Theater Video</label></p>
          <p><label><input type="checkbox"
            v-model="selectedTeam.completedAquaTheater"
            @change="updateTeamProp('completedAquaTheater')"> Aqua Theater<span class="float-right">{{selectedTeamAquaTheaterTime}}</span></label></p>
          <p><label><input type="checkbox"
            v-model="selectedTeam.completedBoardwalkVideo"
            @change="updateTeamProp('completedBoardwalkVideo')"> Boardwalk Video</label></p>
          <p><label><input type="checkbox"
            v-model="selectedTeam.completedBoardwalk"
            @change="updateTeamProp('completedBoardwalk')"> Boardwalk<span class="float-right">{{selectedTeamBoardwalkTime}}</span></label></p>
          <!-- <p><label><input type="checkbox"
            v-model="selectedTeam.completedPromenadeVideo"
            @change="updateTeamProp('completedPromenadeVideo')"> Promenade Video</label></p> -->
          <!-- <p><label><input type="checkbox"
            v-model="selectedTeam.completedPromenade"
            @change="updateTeamProp('completedPromenade')"> Promenade<span class="float-right">{{selectedTeamPromenadeTime}}</span></label></p> -->
          <p><label><input type="checkbox"
            v-model="selectedTeam.completedTheater"
            @change="updateTeamProp('completedTheater')"> Royal Theater<span class="float-right">{{selectedTeamTheaterTime}}</span></label></p>
          <p><label><input type="checkbox"
            v-model="selectedTeam.completedBridge"
            @change="updateTeamProp('completedBridge')"> Bridge<span class="float-right">{{selectedTeamBridgeTime}}</span></label></p>
          <hr>
          <p>ID: {{selectedTeam.id}}</p>
        </div>

      </section>
    </div>

    <nav class="gm-nav">
      <ul>
        <li @click="selectedSessionId = selectedTeamId = null">top</li>
        <li v-if="selectedSessionId" @click="selectedTeamId = null">session details</li>
        <li v-if="selectedTeamId">team details</li>
      </ul>
    </nav>

    <div class="dialoge" v-if="showingDeleteSessionConfirmation">
      <div class="dialoge-container">
        <p>Delete session &quot;{{selectedSessionId}}&quot;?</p>
        <p class="text-right">
          <button v-show="signinState>=1" class="terminal-button" @click="cancelDeleteSession">Cancel</button>
          <button v-show="signinState>=1" class="terminal-button ml-3 danger-button" @click="deleteSelectedSession">Confirm Delete</button>
        </p>
      </div>
    </div>

    <div class="dialoge" v-if="showingResetTeamsConfirmation">
      <div class="dialoge-container">
        <p>Reset (remove) all teams from selected session?</p>
        <ul>
          <li v-for="team in allTeams" :key="team.id">{{team.name}}</li>
        </ul>
        <p class="text-right">
          <button v-show="signinState>=1" class="terminal-button" @click="cancelResetTeams">Cancel</button>
          <button v-show="signinState>=1" class="terminal-button ml-3 danger-button" @click="resetSelectedSessionTeams">Confirm Reset</button>
        </p>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters, mapMutations, mapActions} from 'vuex'
import { getAuth, RecaptchaVerifier, linkWithPhoneNumber, signInWithPhoneNumber } from "firebase/auth";
// import debounce from 'lodash.debounce'

const inputDate = function (when) {
  if (!when) when = new Date();
  const yyyy = when.getFullYear();
  const M = when.getMonth() + 1;
  const d = when.getDate();
  let MM, dd;
  MM = (M < 10) ? ('0' + M.toString()) : M.toString();
  dd = (d < 10) ? ('0' + d.toString()) : d.toString();
  return '' + yyyy + '-' + MM + '-' + dd;
}

const timestampOfLocalDate = function (dateStr) {
  if (!dateStr) return null
  let date = new Date (dateStr + ' GMT')
  let offset = date.getTimezoneOffset() * 60000
  return date.valueOf() + offset
}

const timeText = function (t) {
  if (isNaN(t)) return '00:00';
  const m = Math.floor(t / 60);
  const s = t % 60;
  let mm, ss;
  mm = (m < 10) ? ('0' + m.toString()) : m.toString();
  ss = (s < 10) ? ('0' + s.toString()) : s.toString();
  return '' + mm + ':' + ss;
}

export default Vue.extend({
  name: 'GameMaster',
  props: {
  },
  data () {
    return {
      signinForm: {
        user: '',
        pass: ''
      },

      justSignedIn: false,
      showingPwResetForm: false,
      pwResetForm: {
        oldPass: '',
        pass1: '',
        pass2: ''
      },

      otpQrUrl: '',
      otpEntry: '',

      signinPhone: {
        text: '',
        number: null,
        full: '',
        valid: false,
        code: ''
      },
      notNumbersPattern: /[^0-9]/g,
      recaptchaVerifier1: null,
      recaptchaVerifier2: null,
      confirmationResult: null,

      columns: ['home'],

      showSessionsFromDate:  '2001-01-01',
      showSessionsToDate:    '2001-01-01',
      showAnyTimeSessions:   true,
      showConcludedSessions: false,

      newSessionPin: '',
      newSession: {
        id: '',
        open: true,
        allowSelfStart: true,
        started: false
      },
      selectedSessionId: null,
      showingDeleteSessionConfirmation: false,

      selectedTeamId: null,
      showingResetTeamsConfirmation: false,
    }
  },
  computed: {
    ...mapGetters([
      'signinBusy',
      'user',
      'uid',
      'userMustResetPass',
      'userHasPhoneSignin',
      'otpConfigured',
      'otpCurrent',
      'mfaSent',
      'mfaVerified',
      'gmProfile',
      'scenarioId',
      'scenario',
      'sessions',
      'allTeams',
      'teamRankings',
      'today'
    ]),
    userIsNotLoggedIn () {
      if (this.user) return this.user.isAnonymous;
      return true;
    },
    userIsActuallyLoggedIn () {
      return (this.user && this.user.uid && !this.user.isAnonymous)
    },
    // signinState () {
    //   // 0 - Not logged in
    //   // 1 - Logged in, but not a GM or Admin
    //   // 2 - Logged in, but password reset required
    //   // 3 - Logged in, but MFA not set up
    //   // 4 - MFA set up, but MFA not yet sent
    //   // 5 - MFA sent, but not yet verified
    //   // 6 - Logged in, and MFA verified
    //   if (!this.user || this.user.isAnonymous) return 0;
    //   if (!this.gmProfile) return 1;
    //   if (this.userMustResetPass) return 2;
    //   if (this.mfaVerified) return 6;
    //   if (this.mfaSent) return 5;
    //   return (this.userHasPhoneSignin) ? 4 : 3;
    // },
    signinState () {
      // 0 - Not logged in
      // 1 - Logged in, but not a GM or Admin
      // 2 - Logged in, but password reset required
      // 3 - Logged in, but OTP not configured
      // 4 - OTP configured, but not current
      // 5 - Logged in, and OTP current
      if (this.signinBusy) return -1;
      if (!this.user || this.user.isAnonymous) return 0;
      if (!(this.gmProfile && this.gmProfile.id)) return 1;
      if (this.userMustResetPass) return 2;
      if (this.otpCurrent) return 5;
      if (this.otpConfigured) return 4;
      return 3;
    },
    userDisplayName () {
      if (this.userIsNotLoggedIn) return 'nobody'
      if (this.user.displayName) return this.user.displayName
      if (this.user.email) return this.user.email
      if (this.user.phoneNumber) return this.user.phoneNumber
    },
    passwordsDontMatch () {
      return (this.pwResetForm.pass1 && this.pwResetForm.pass2 && this.pwResetForm.pass1===this.pwResetForm.pass2) ? false : true
    },
    userPhoneNumber () {
      return (this.userIsNotLoggedIn) ? '' : this.user.phoneNumber
    },
    showSessionsFromTimestamp () {
      return timestampOfLocalDate(this.showSessionsFromDate)
    },
    showSessionsToTimestamp () {
      return timestampOfLocalDate(this.showSessionsToDate) + 86399999
    },
    sortedSessionIds () {
      const ids = []
      let session;
      if (this.sessions) {
        for (let id in this.sessions) {
          session = this.sessions[id]
          if (this.showConcludedSessions || !session.concluded) {
            if ((this.showAnyTimeSessions && !session.scheduledFor) || (session.scheduledFor && this.showSessionsFromTimestamp < session.scheduledFor && session.scheduledFor < this.showSessionsToTimestamp)) {
              ids.push(id)
            }
          }
        }
        ids.sort((a,b)=>{
          if (this.sessions[a].scheduledFor && this.sessions[b].scheduledFor) {
            if (this.sessions[a].scheduledFor === this.sessions[b].scheduledFor) return 0
            return (this.sessions[a].scheduledFor < this.sessions[b].scheduledFor) ? -1 : 1
          }
          if (this.sessions[a].scheduledFor) return 1
          if (this.sessions[b].scheduledFor) return -1
          return 0
        })
      }
      return ids
    },
    newSessionPinIsDuplicate () {
      return this.sessions.hasOwnProperty(this.scenarioId + '-' + this.newSessionPin)
    },
    selectedSession () {
      // if (this.selectedSessionId) return this.sessions[this.selectedSessionId];
      if (this.selectedSessionId) return this.$store.getters.session
      return null
    },
    selectedSessionCompletedTraining () {
      let members = []
      for (let t in this.allTeams) {
        if (this.allTeams[t].completedBridge) {
          members = members.concat(this.allTeams[t].members)
        }
      }
      members.sort()
      return members
    },
    sortedTeams () {
      const sorted = []
      let t, l, team;
      for (t=0, l=this.teamRankings.length; t<l; t++) {
        team = this.teamRankings[t]
        console.log('team.requiresAssistance', team.requiresAssistance)
        if (team.requiresAssistance) {
          sorted.push(team)
        }
      }
      for (t=0, l=this.teamRankings.length; t<l; t++) {
        team = this.teamRankings[t]
        if (!team.requiresAssistance) {
          sorted.push(team)
        }
      }
      return sorted
    },
    selectedTeam: {
      get () {
        if (this.selectedSession && this.allTeams && this.selectedTeamId) {
          return this.allTeams[this.selectedTeamId]
        }
        return null
      },
      set (value) {
        console.log('set selectedTeam', value)
      }
    },
    selectedTeamAquaTheaterTime () {
      if (this.selectedTeam) return timeText(this.selectedTeam.timeAquaTheater);
      return ''
    },
    selectedTeamBoardwalkTime () {
      if (this.selectedTeam) return timeText(this.selectedTeam.timeBoardwalk);
      return ''
    },
    selectedTeamCentralParkTime () {
      if (this.selectedTeam) return timeText(this.selectedTeam.timeCentralPark);
      return ''
    },
    // selectedTeamPromenadeTime () {
    //   if (this.selectedTeam) return timeText(this.selectedTeam.timePromenade);
    //   return ''
    // },
    selectedTeamTheaterTime () {
      if (this.selectedTeam) return timeText(this.selectedTeam.timeTheater);
      return ''
    },
    selectedTeamBridgeTime () {
      if (this.selectedTeam) return timeText(this.selectedTeam.timeBridge);
      return ''
    },
    focusColumn () {
      if (this.selectedTeam) return 'column-three'
      if (this.selectedSession) return 'column-two'
      return 'column-one'
    }
  },
  methods: {
    ...mapMutations([
      'sessionId',
      'session',
    ]),
    ...mapActions([
      'signOut',
      'subscribeToGmProfile',
      'subscribeToSessions',
      'createSession',
      'updateSession',
      'deleteSession',
      'subscribeToTeams',
      'updateTeam',
      'deleteTeams'
    ]),

    initRecaptcha () {
      console.log('initRecaptcha')
      const auth = getAuth();
      this.recaptchaVerifier1 = new RecaptchaVerifier(this.$refs.linkPhoneButton, {
        'size': 'invisible',
        'callback': (response) => {
          console.log('repatchaVerifierCallback', response)
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // this.onSignInSubmit();
        }
      }, auth);
      this.recaptchaVerifier2 = new RecaptchaVerifier(this.$refs.signinButton, {
        'size': 'invisible',
        'callback': (response) => {
          console.log('repatchaVerifierCallback', response)
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // this.onSignInSubmit();
        }
      }, auth);
    },

    // Sign In with Email/Password
    signIn () {
      this.busy = true;
      this.$store.dispatch('signIn', this.signinForm)
      .then(user => {
        this.justSignedIn = true
      })
      .catch(error => {
        alert(error.message);
      })
      .finally(() => {
        this.busy = this.authActionsInproc;
        this.signinForm = {
          user: '',
          pass: ''
        }
      });
    },
    // Sign In through Google
    signInThroughProvider (provider) {
      this.busy = true;
      this.$store.dispatch('signInThroughProvider', provider)
      .then(user => {
        this.justSignedIn = true
      })
      .catch(error => {
        alert(error.message);
      })
      .finally(() => {
        this.busy = this.authActionsInproc;
      });
    },

    // Password Reset
    showPwResetForm () {
      this.showingPwResetForm = !this.showingPwResetForm
    },

    async submitPwReset () {
      if (this.passwordsDontMatch) return false
      const payload = {
        newPass: this.pwResetForm.pass1
      }
      if (!this.justSignedIn) payload.oldPass = this.pwResetForm.oldPass
      const success = await this.$store.dispatch('updateCurrentUserPassword', payload)
      if (success) {
        this.$store.dispatch('updateGmProfile', {resetPass: false})
      }
      this.pwResetForm = {
        oldPass: '',
        pass1: '',
        pass2: ''
      }
      this.showingPwResetForm = false
    },

    // MFA via Firebase Phone Auth

    parsePhoneNumber (phone, event) {
      // console.log('parsePhoneNumber', phone, event)
      if (typeof phone.text === 'number') {
        phone.text = phone.text.toString()
      }
      if (phone.text.charAt(0) === '+') {
        this.$set(phone, 'number', parseInt(phone.text.replaceAll(this.notNumbersPattern, '')))
        this.$set(phone, 'full', phone.text)
        this.$set(phone, 'valid', null)
        return;
      }
      let value = phone.text.replaceAll(this.notNumbersPattern, ''), full = '';
      this.$set(phone, 'number', parseInt(value))
      if (value.charAt(0) === '1') {
        value = value.substring(1)
      }
      let length = value.length
      if (length<3) return;
      let areaCode = '', exchange = '', subscriber = '', extension = ''
      if (length>0) {
        areaCode = value.substring(0,3)
      }
      if (length>3) {
        exchange = value.substring(3,6)
      }
      if (length>6) {
        subscriber = value.substring(6,10)
      }
      this.$set(phone, 'valid', (length>=10)?true:false)
      if (length>10) {
        extension = value.substring(10)
      }
      if (extension) {
        value = `${areaCode}-${exchange}-${subscriber} x${extension}`
        full = `+1 ${areaCode}-${exchange}-${subscriber} x${extension}`
      } else if (subscriber) {
        value = `${areaCode}-${exchange}-${subscriber}`
         full = `+1 ${areaCode}-${exchange}-${subscriber}`
      } else if (exchange) {
        value = `${areaCode}-${exchange}`
      }
      this.$set(phone, 'text', value)
      this.$set(phone, 'full', full)
      event.target.focus();
      event.target.setSelectionRange(value.length, value.length);
    },
    async linkPhone () {
      console.log('linkPhone')
      if (!this.signinPhone.valid) return;
      const success = await this.$store.dispatch('linkToNewPhone', {
        phone: this.signinPhone.full,
        appVerifier: this.recaptchaVerifier2
      })
      if (!success) {
        // send error
      }
    },
    async sendPhoneCode () {
      console.log('sendPhoneCode')
      if (!this.userPhoneNumber) return;
      const success = await this.$store.dispatch('sendPhoneCode', {
        appVerifier: this.recaptchaVerifier2
      })
      if (!success) {
        // send error
      }
    },
    async confirmPhoneCode () {
      console.log('confirmPhoneCode', this.signinPhone.code)
      const success = await this.$store.dispatch('confirmPhoneCode', {
        code: this.signinPhone.code
      })
      if (!success) {
        // wrong code
      }
    },

    // OTP Authenticator

    async generateOtpSeed () {
      console.log('calling generateOtpSeed')
      const response = await this.$store.dispatch('generateOtpSeed')
      console.log('>', response)
      if (response && response.qrUrl) {
        this.otpQrUrl = response.qrUrl
      }
    },

    async checkOtpStatus () {
      console.log('calling checkOtpStatus')
      const response = await this.$store.dispatch('checkOtpStatus')
      console.log('>', response)
    },

    async checkOtp () {
      console.log('calling checkOtp')
      const response = await this.$store.dispatch('checkOtp', {otp: this.otpEntry})
      console.log('>', response)
    },

    // Session List
    getFromToTimestamps () {
      let fromDate = new Date(this.showSessionsFromDate + ' GMT')
      let toDate   = new Date(this.showSessionsToDate   + ' GMT')
      let offset = fromDate.getTimezoneOffset() * 60000
      return {
        from: fromDate.valueOf() + offset,
        to:   toDate.valueOf()   + offset
      }
    },
    onFromDateChange () {
      let {from, to} = this.getFromToTimestamps()
      console.log('from', from)
      console.log('to  ', to)
      if (from > to) {
        to = from
        this.showSessionsToDate = this.showSessionsFromDate
      }
    },
    onToDateChange () {
      let {from, to} = this.getFromToTimestamps()
      console.log('from', from)
      console.log('to  ', to)
      if (from > to) {
        from = to
        this.showSessionsFromDate = this.showSessionsToDate
      }
    },

    tableClassOfSessionId (id) {
      if (id === this.selectedSessionId) return 'text-white'
      if (this.sessions[id].concluded) return 'text-gray'
      return ''
    },

    async createNewSession () {
      if (!this.newSessionPin) return false;
      this.newSession.id = this.scenarioId+'-'+this.newSessionPin;
      await this.createSession(this.newSession)
      this.selectSession(this.newSession.id)
    },
    selectSession (session) {
      this.selectTeam(null)
      if (session === null) {
        this.selectedSessionId = null
        return
      }
      else if (typeof session === 'string') {
        if (this.sessions.hasOwnProperty(session)) {
          session = this.sessions[session]
        } else {
          this.selectedSessionId = null
          return
        }
      }
      this.selectedSessionId = session.id
      this.sessionId(session.id)
      this.session(this.sessions[this.selectedSessionId]);
      this.subscribeToTeams()
    },
    saveSelectedSession () {
      console.log('saveSelectedSession', this.selectedSession)
      if (this.selectedSession.date && this.selectedSession.time) {
        let scheduledFor = new Date(this.selectedSession.date + ' ' + this.selectedSession.time + ' GMT');
        let offset = scheduledFor.getTimezoneOffset() * 60000
        scheduledFor = new Date(scheduledFor.valueOf() + offset)
        console.log('scheduled for', scheduledFor.toString(), ' > ', scheduledFor.valueOf());
        this.selectedSession.scheduledFor = scheduledFor.valueOf();
      }
      this.updateSession(this.selectedSession)
    },
    startSession () {
      const update = {
        id: this.selectedSession.id
      }
      update['started'] = true
      this.updateSession(update)
    },

    confirmDeleteSession () {
      if (!this.selectedSessionId) return null;
      this.showingDeleteSessionConfirmation = true;
    },
    cancelDeleteSession () {
      this.showingDeleteSessionConfirmation = false;
    },
    async deleteSelectedSession () {
      if (this.selectedSessionId) {
        await this.deleteSession({id: this.selectedSessionId})
        this.selectSession(null)
      }
      this.showingDeleteSessionConfirmation = false;
    },

    // Teams
    selectTeam (team) {
      if (team === null) {
        this.selectedTeamId = null
        return
      }
      this.selectedTeamId = team.id
    },
    clearRequestForAssistance () {
      this.updateSelectedTeam({requiresAssistance: 0})
    },
    addHint () {
      this.updateSelectedTeam({hintsRemaining: this.selectedTeam.hintsRemaining+1})
    },
    subtractHint () {
      if (this.selectedTeam.hintsRemaining > 0) {
        this.updateSelectedTeam({hintsRemaining: this.selectedTeam.hintsRemaining-1})
      }
    },
    addPenalty () {
      // this.updateSelectedTeam({timePenalty: this.selectedTeam.timePenalty+30})
    },
    subtractPenalty () {
      // if (this.selectedTeam.timePenalty >= 30) {
      //   this.updateSelectedTeam({timePenalty: this.selectedTeam.timePenalty-30})
      // }
    },
    updateTeamProp (prop) {
      const update = {
        id: this.selectedTeam.id
      }
      update[prop] = this.selectedTeam[prop]
      if (typeof update[prop] === 'boolean') update[prop] = update[prop] ? 1 : 0
      this.updateTeam(update)
    },
    updateSelectedTeam (update) {
      update.id = this.selectedTeam.id
      this.updateTeam(update)
    },

    teamStatus (team) {
      if (team.requiresAssistance) return '<i class="bi-flag-fill"> Requires Assistance';
      // if (!(this.selectedSession && this.selectedSession.started)) {
      //   return team.ready ? 'READY!' : ''
      // }
      if (team.completedBridge) return 'DONE!';
      let count = 0
      if (team.ready) {
        if (team.completedAquaTheater) count++
        if (team.completedBoardwalk)   count++
        if (team.completedCentralPark) count++
        // if (team.completedPromenade)   count++
        if (team.completedTheater)     count++
      }
      if (count) return '' + count + ' of 5'
      return team.ready ? 'READY!' : ''
    },

    confirmResetTeams () {
      if (!this.selectedSessionId) return null;
      this.showingResetTeamsConfirmation = true;
    },
    cancelResetTeams () {
      this.showingResetTeamsConfirmation = false;
    },
    async resetSelectedSessionTeams () {
      if (this.selectedSessionId && this.allTeams) {
        await this.deleteTeams({
          session: this.selectedSession,
          teams:   this.allTeams
        })
      }
      this.showingResetTeamsConfirmation = false;
    },

  },
  mounted () {
    console.log('GameMaster.mounted', this.user)
    this.selectedSessionId = null
    this.selectedTeamId = null
    const showFrom = new Date()
    if (showFrom.getMonth() === 0) {
      showFrom.setMonth(11)
      showFrom.setFullYear(showFrom.getFullYear()-1)
    } else {
      showFrom.setMonth(showFrom.getMonth()-1)
    }
    console.log('showFrom', showFrom.toLocaleString())
    this.showSessionsFromDate = this.$store.getters.inputDate(showFrom)
    this.showSessionsToDate = this.today
    if (this.userIsActuallyLoggedIn) {
      this.subscribeToGmProfile()
      // this.subscribeToSessions()
    }
    // this.initRecaptcha()
  },
  watch: {
    user (newVal, oldVal) {
      console.log('user changed from', oldVal, 'to', newVal)
      if (this.userIsActuallyLoggedIn) {
        this.subscribeToGmProfile()
        // this.subscribeToSessions()
      }
      // Signed Out
      if (!newVal) {
        this.justSignedIn = false
      }
    },
    gmProfile (newVal, oldVal) {
      console.log('gmProfile changed from', oldVal, 'to', newVal)
      if (newVal && newVal.id) {
        this.$store.dispatch('checkOtpStatus')
        this.subscribeToSessions()
      }
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.game-master-page {
  /* background-image: url("~@/assets/bg/bg-underwater-ani.gif");
  background-size: 101vw 106vh;
  background-position-y: -6vh; */
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow: hidden;
  position: relative;
  left: 0;
  transition: left 0.25s;
}

/* Navbar */
nav.gm-nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: #0f0;
  text-shadow: 0 0 4px rgba(0,255,0,0.625);
  background-color: rgba(0,0,0,0.5);
  border-bottom: 1px solid #0f0;
}
nav.gm-nav>ul {
  list-style: none;
  margin: 0;
  padding: 0 0.5em;
}
nav.gm-nav>ul>li {
  display: inline-block;
  margin: 0.5rem 0;
}
nav.gm-nav>ul>li::before {
  content: '>';
  margin: 0 0.25rem;
}

/* Columns */
.column-shifter {
  width: 300vw;
  position: absolute;
  top: 2rem;
  padding-left: 1rem;
  left: 0;
  transition: left 500ms;
}
.column-shifter.column-two {
  left: calc(1rem - 100vw);
}
.column-shifter.column-three {
  left: calc(2rem - 200vw);
}

@media screen and (min-width: 992px) {
  .column-shifter {
    width: 150vw;
  }
  .column-shifter.column-two {
    left: 0;
  }
  .column-shifter.column-three {
    left: calc(0.5rem - 50vw);
  }
}

@media screen and (min-width: 1440px) {
  .column-shifter {
    width: calc(100vw + 2px);
  }
  .column-shifter.column-two {
    left: 0;
  }
  .column-shifter.column-three {
    left: 0;
  }
}
/* .game-master-page.shift-left {
  left: calc(1rem - 50vw);
} */

section.column {
  display: inline-block;
  width: calc(100vw - 1rem);
  margin: 0;
  padding: 1rem 1rem 2rem 0;
  height: calc(100vh - 2rem);
  overflow-y: auto;
}
@media screen and (min-width: 992px) {
  section.column {
    vertical-align: top;
    width: calc(50vw - 0.5rem);
    padding-bottom: 1rem;
  }
}
@media screen and (min-width: 1440px) {
  section.column {
    vertical-align: top;
    width: 33vw;
    padding-bottom: 0rem;
  }
}
section.column::-webkit-scrollbar {
  width: 0px;
}
section.column::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
section.column::-webkit-scrollbar-thumb {
  background-color: #0b0;
  outline: none;
}

div.container {
  margin: 0;
  padding: 1rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: bolder;
  color: #0f0;
  text-shadow: 0 0 4px rgba(0,255,0,0.625);
  background: rgba(0,0,0,0.5);
  border: 1px solid #0f0;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0 0 4px rgba(0,255,0,0.5);
}
/* @media screen and (min-width: 992px) {
  div.container {
    display: inline-block;
    width: calc(50% - 3rem);
    vertical-align: top;
  }
  div.container:nth-child(even) {
    margin-left: 0;
  }
} */

.teams-table {
  margin-bottom: 2rem;
}

div.sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 30rem;
  background-color: rgba(0,0,0,0.625);
  background: linear-gradient(to right, rgba(0,0,0,0.75), rgba(0,0,0,0));
  padding: 2rem 8rem 2rem 2rem;
  font-size: 2rem;
}
h2 {
  font-size: 2rem;
  margin-top: 0;
}
h3 {
  margin-top: 0;
}
ul {
  list-style: none;
}
hr {
  border: none;
  border-bottom: 1px solid #0f0;
  margin: 1.5em 0;
}
button {
  color: #0f0;
  background: none;
  border: 1px solid #0f0;
  border-radius: 10px;
  cursor: pointer;
}
button:disabled {
  color: #777;
  border: 1px solid #777;
  cursor: not-allowed;
}
button:hover:not(:disabled) {
  color: #fff;
  border: 1px solid #fff;
}

.lead {
  color: #def;
}

.clickable {
  cursor: pointer;
}
.form-group {
  margin-bottom: 1rem;
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
textarea {
  display: block;
  width: 100%;
  color: #fff;
  background-color: transparent;
  border: 2px solid #fff;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
input[type="date"],
input[type="time"] {
  display: inline-block;
  color: initial;
  background-color: rgba(127, 255, 127, 0.375);
  border: 2px solid #0f0;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0 0.25em;
  margin-right: 0.5rem;
}
.form-group p {
  font-size: 1rem;
  margin-top: 0.25rem;
}
.enter-button {
  margin-top: 0;
  text-align: center;
}
.enter-button:hover {
  cursor: pointer;
  color: #0f0;
  animation-duration: 0.25s;
  animation-name: flash-green;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.flash-green {
  animation-duration: 0.25s;
  animation-name: flash-green;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes flash-green {
  0% {
    color: #eee;
  }
  100% {
    color: #0f0;
  }
}

.text-white {
  color: #fff;
}
.text-gray {
  color: #777;
  text-shadow: none;
}
.text-red {
  color: #f00 !important;
  text-shadow: 0 0 4px rgba(255,0,0,0.625);
}

.big-button {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: bolder;
  color: #0f0;
  text-shadow: 0 0 4px rgba(0,255,0,0.625);
  padding: 8px;
  background: rgba(0,0,0,0.5);
  border: 1px solid #0f0;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0 0 4px rgba(0,255,0,0.5);
}
.big-button:hover {
  cursor: pointer;
  text-shadow: 0 0 6px rgba(0,255,0,0.75);
  box-shadow: 0 0 6px rgba(0,255,0,0.625);
}
.big-button:disabled {
  cursor:not-allowed;
  color: #999;
  border-color: #999;
  text-shadow: none;
  box-shadow: none;
}
/* .big-button:hover {
  cursor:not-allowed;
  text-shadow: 0 0 6px rgba(0,255,0,0.75);
  box-shadow: 0 0 6px rgba(0,255,0,0.625);
} */
.danger-button {
  border-color: #d00 !important;
  color: #d00 !important;
}
.danger-button:hover {
  border-color: #f00 !important;
  color: #f00 !important;
}

table {
  width: 100%;
}
th, td {
  text-align: right;
  padding: 0.5em;
  border-bottom: 1px solid #0f0;
}
th:first-child,
td:first-child,
th:nth-child(2),
td:nth-child(2) {
  text-align: left;
}
th {
  font-size: 1.1em;
}

.dialoge {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
}
.dialoge-container {
  max-width: 1000px;
  margin: 2rem auto;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: bolder;
  color: #eee;
  text-shadow: 0 0 4px rgba(200,200,200,0.625);
  background: rgba(0,0,0,0.5);
  border: 1px solid #eee;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0 0 4px rgba(255,255,255,0.5);
}
.dialoge .terminal-button {
  color: #eee;
  text-shadow: 0 0 4px rgba(200,200,200,0.625);
  border-color: #eee;
  box-shadow: 0 0 4px rgba(255,255,255,0.5);
}
</style>
